@import 'styles/responsive.less';
@import 'styles/variables.less';

.appFeatures {
  .controlBottom {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    @media (min-width: @screen-md) {
      display: none;
    }
  }
}

.feature {
  width: calc(100vw - 60px);
  max-width: 500px;

  border-radius: 30px;
  margin: 0 10px;

  display: flex;
  padding: 20px;
  flex-direction: column;
  justify-content: flex-start;

  background: @wellness;
  color: @nueblue;

  @media (min-width: @screen-md) {
    padding: 40px;
    margin: 0 20px;
    max-width: 700px;
  }

  @media (min-width: @screen-lg) {
    max-width: 80vw;
    margin: 0 40px;
  }

  h4 {
    font-family: @baskervilleRegular;
    text-transform: lowercase;
    font-size: 36px;
    line-height: 1em;
    margin-bottom: 36px;

    flex-grow: 1;

    @media (min-width: @screen-md) {
      font-size: 48px;
    }

    @media (min-width: @screen-lg) {
      font-size: 70px;
      line-height: 70px;
      margin-bottom: 60px;
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  .textContent {
    display: flex;
  }

  .controls {
    display: none;
  }

  @media (min-width: @screen-md) {
    flex-direction: row-reverse;
    gap: 100px;
    justify-content: space-around;
    .textContent {
      flex-direction: column;
      gap: 40px;
      max-width: 500px;
      margin-bottom: 200px;
    }

    .controls {
      display: flex;
    }
  }
}

.image {
  img {
    //max-width: min(50vw, 250px);
    max-height: 40vh;
    @media (min-width: @screen-lg) {
      max-width: 610px;
      max-height: 720px;
    }
  }
}

.stepNumber {
  margin-right: 30px;
  font-family: @baskerville;
  font-size: 26px;
  letter-spacing: -0.3px;
}

.name {
  color: @calmblue;
  font-family: @gotham;
  font-size: 10px;
  line-height: 26px;
  letter-spacing: -0.4px;
  text-transform: uppercase;

  margin-bottom: 10px;

  @media (min-width: @screen-md) {
    font-size: 14px;
  }

  @media (min-width: @screen-lg) {
    font-size: 16px;
  }
}

.description {
  font-family: @gothamBook;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: -0.5px;
  ul {

    li {
      border-bottom: 1px solid #0d345c80;
      display: flex;
      font-size: 14px;
      line-height: 20px;
      flex-direction: row;
      padding: 16px 0;

      gap: 16px;

      &::before {
        flex-shrink: 0;
        background-image: url('../../../../assets/checkmark.svg');
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        padding-top: 8px;
        width: 14px;
        height: 10px;
        display: block;
        content: '';
        border-radius: 9999px;
      }

      &:last-child {
        border-bottom: none;
      }
    }
  }
}
