@import 'styles/responsive.less';
@import 'styles/variables.less';
@import 'styles/easing.less';

.main-page-network {
  position: relative;
  overflow: hidden;

  :global .top-nav-variant .logo-with-text svg.resize-mobile {
    width: 123px;
    height: 52.5px;
    @media @desktop {
      width: auto;
      height: auto;
    }
  }

  .firstContainer {
    padding-top: 8em;
    @media @mobile {
      padding-top: 0;
    }
  }

  .safety__desktop {
    padding-top: 6em;
    padding-bottom: 1em;
    text-align: center;
    font-family: @baskervilleRegular;
    @media @mobile {
      padding: 0;
      display: none;
    }

    .safetyTitle {
      font-family: @baskervilleRegular;
      line-height: 30px;
      font-size: 4em;
      text-align: center;
      letter-spacing: -0.3px;
      font-style: normal;
      @media @mobile {
        font-size: 2em;
      }
    }
    .safetySubTitle{
      padding-top: 1em;
      font-size: 2em;
      @media @mobile {
        font-size: 1.5em;
      }
    }
    .safetyItems {
      font-family: @gothamBook;
      font-size: 1.2em;
      line-height: 1.5em;
      @media @mobile {
        font-size: 1em;
      }
    }

  }

  .safety__mobile {
    //padding-top: 2em;
    //padding-bottom: 1em;
    text-align: left;
    font-family: @baskervilleRegular;


    .safetyTitle {
      font-family: @baskervilleRegular;
      font-size: 2em;
      text-align: center;
      letter-spacing: -0.3px;
      font-style: normal;
      @media @desktop {
        display: none;
      }
    }
    .safetySubTitle{
      padding-top: 1em;
      font-size: 1.8em;
      @media @desktop {
        display: none;
      }
    }
    .safetyItems {
      padding-top: 1em;
      font-family: @gothamBook;
      font-size: 1.2em;
      line-height: 1.5em;
      @media @mobile {
        font-size: 1em;
      }
    }

  }

  .hero_background {
    position: absolute;
    z-index: 0;
    background-size: cover !important;

    height: 650px;
    @media @mobile {
      background: url('./assets/hero.png') @calmblue center;
      opacity: 0.5;
    }

    /*@media @desktop {
      background: url('./assets/hero.png') center @calmblue;
      height: 700px;
    }*/

    width: 100%;
  }
  .hero_background_2 {
    position: absolute;
    z-index: 0;
    background-size: cover !important;

    height: 650px;
    @media @mobile {
      background: url('./assets/refer.jpg') @calmblue center;
      opacity: 0.5;
    }

    /*@media @desktop {
      background: url('./assets/hero.png') center @calmblue;
      height: 700px;
    }*/

    width: 100%;
  }
  .hero_background_3 {
    position: absolute;
    z-index: 0;
    background-size: cover !important;
    height: 650px;

    @media @mobile {
      background: url('./assets/safety.png') @calmblue center;
      opacity: 0.3;
      margin-left: -10%;
      margin-top: -2em;
      height: 800px;
    }

    /*@media @desktop {
      background: url('./assets/hero.png') center @calmblue;
      height: 700px;
    }*/

    width: 160%;
  }

  .container_on_hero {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;

    img  {
      width: auto;
      height: 50%;
      @media @desktop {
        border-radius: 50px;
      }
      @media @mobile {
        display: none;
      }
    }


  }

  .hero {
    z-index: 1;
    color: @nueblue;
    height: 650px;
    gap: 2em;
    @media desktop {
      height: 650px;
    }
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    @media @desktop {
      width: 100%;
    }

    .lefty {
      text-align: left;
      gap: 2em;
    }

    h1 {
      font-family: @gotham;
      font-size: 20px;
      line-height: 26px;
      text-align: center;

      span {
        display: block;
        font-size: 14px;
        line-height: 26px;
        text-align: center;
      }
    }

    h2 {
      font-family: @baskervilleRegular;
      line-height: 30px;
      font-size: 32px;
      max-width: 1004px;
      text-align: center;
      letter-spacing: -0.3px;
      font-style: normal;

      .translated {
        font-size: 4rem;
        line-height: 6rem;
        @media @mobile {
          padding-top: 10em;
          font-size: 2rem;
          line-height: 3rem;
        }
      }

      .special {
        font-family: @gothamBook;
        font-size: 22px;
        line-height: 32px;
        @media @desktop {
          font-size: 35px;
          line-height: 60px;
          letter-spacing: -1.3px;
        }
      }

      @media @desktop {
        font-size: 50px;
        line-height: 60px;
      }
    }
    h3 {
      font-family: @baskervilleRegular;
      font-size: 1.5em;
    }

    .referSubtitle{
      font-family: @baskervilleRegular;
      font-size: 2em;
      text-align: center;
    }

    .img1  {
      width: auto;
      height: 80%;
      @media @desktop {
        border-radius: 50px;
      }
      @media @mobile {
        display: none;
      }
    }

    .img2  {
      width: auto;
      height: 80%;
      @media @desktop {
        border-radius: 50px;
      }
      @media @mobile {
        display: none;
      }
    }

    .img3  {
      width: auto;
      height: 80%;
      @media @desktop {
       // border-radius: 50px;
      }
      @media @mobile {
        display: none;
      }
    }
  }

  .sub-hero {
    max-width: 1023px;
    margin: auto;
    display: flex;
    flex-direction: row;
    gap: 48px;
    padding-bottom: 70px;
    margin-bottom: 100px;

    border-bottom: 2px solid white;

    @media @mobile {
      flex-direction: column;
    }

    > div {
      flex-grow: 1;
    }
    :global .left,
    :global .right {
      font-family: @gothamBook;
      flex-grow: 1;
    }

    :global .left {
      font-size: 26px;
      line-height: 38px;
    }
    :global .right {
      font-size: 16px;
      line-height: 26px;
    }
  }

  :global .section {
    margin-top: -100px;
    padding-top: 50px;
    padding-bottom: 100px;
    margin-bottom: -100px;
    @media @desktop {
      padding-top: 100px;
    }
  }



  .resultsSection {
    margin: 80px 0;

    @media @desktop {
      margin: 140px 0;
    }

    p.statsDescript {
      font-size: 14px;
      line-height: 20px;
      font-family: @gothamBook;
      margin-bottom: 40px;

      @media @desktop {
        font-size: 16px;
        line-height: 26px;
        margin-bottom: 56px;
      }
    }

    .results {
      display: flex;
      flex-direction: column;

      @media @desktop {
        gap: 60px;
        flex-direction: row;
      }

      :global img {
        width: 100%;
        max-width: 450px;
      }
      .statEntries {
        font-family: @baskerville;
        font-size: 26px;
        letter-spacing: -0.3px;

        > * {
          padding: 32px 0;
          border-bottom: 1px solid white;
        }
      }

      :global a.button {
        margin-top: 40px;
        min-width: 240px;
        .icon {
          padding-top: 2px;
          width: 14px;
        }
      }
    }
  }

  .sectionFix {
    margin-top: -6em;
    padding-bottom:  4em;
  }

  .teamSection {
    z-index: 2;
    padding: 25px 0;
    background: url('../../assets/noise.png') center center repeat @calmblue;
    margin-top: -40px;
    color: @eggshell;
  }

  .offerSection {
    padding: 3em 0em;
    .app-section {
      display: flex;
      flex-direction: column;
      gap: 30px;
      //margin-bottom: 80px;

      @media @desktop {
        flex-direction: row-reverse;
        align-items: center;
      }

      .image-wrapper {
        min-height: 340px;
        width: 100%;
        flex: 1;
        // background-image: url('./images/app.png');
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        @media @desktop {
          flex: 1 1 50%;
          min-height: 500px;
          margin: 30px 0;
        }
      }
      .content {
        @media @desktop {
          flex: 1 1 50%;
        }
        h4 {
          font-size: 56px;
          font-family: @baskerville;
          margin-bottom: 50px;
          @media @desktop {
            width: 100%;
            margin-bottom: 80px;
          }
        }
      }
      .features {
        margin-top: 26px;
        .single-feature {
          border-bottom: 1px solid rgba(255, 255, 255, 0.4);
          padding-bottom: 20px;
          margin-bottom: 20px;
          font-family: @gothamBook;

          .title {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 20px;
            line-height: 32px;
            font-family: @gothamBook;

            .icon {
              width: 26px;
              height: 26px;
              border: 1px solid rgba(255, 255, 255, 0.4);
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 99999px;
              svg {
                height: 8px;
                width: 12px;
              }
            }
          }

          .text {
            margin-top: 16px;
            font-size: 14px;
            line-height: 1.6em;
          }

          ul {
            margin: 25px 0;
            margin-left: 24px;
            li {
              border-bottom: 1px solid #0d345c80;
              display: flex;
              font-size: 14px;
              line-height: 20px;
              flex-direction: row;
              padding: 16px 0;

              gap: 16px;

              &::before {
                flex-shrink: 0;
                background-image: url('../../assets/checkmark.svg');
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
                padding-top: 8px;
                width: 14px;
                height: 10px;
                display: block;
                content: '';
                border-radius: 9999px;
              }

              &:last-child {
                border-bottom: none;
              }
            }
          }
        }
      }

      .app-download {
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        justify-self: center;
        align-items: center;
        gap: 20px;

        @media @desktop {
          flex-direction: row;
        }
        p {
          font-size: 28px;
          font-family: @baskerville;
          line-height: 32px;
        }

        img {
          height: 45px;
        }
      }
    }
  }

  .footerSection {
    h3.sectionTitle {
      font-size: 30px;
      font-family: @baskerville;
      margin-bottom: 40px;
      text-align: center;

      @media @desktop {
        font-size: 50px;
        margin-bottom: 60px;
      }
    }
  }


  .classResults {
    display: flex;
    flex-direction: column;
    //height: 700px;
    font-family: @baskervilleRegular;
    align-items: center;
    justify-content: space-evenly;

    @media @mobile {
      //height: 500px;
    }

    .classResultsItalicRequired {
      font-family: @baskerville;
    }

    .classResultsTop {
      font-size: 4em;
      font-weight: 400;
      //line-height: 70px;

      @media @mobile {
        font-size: 3em;
        font-weight: 200;
        //line-height: 30px;
      }
    }
    .classResultsBottom {
      padding: 3em;
      padding-left: 1em;
      padding-right: 1em;
      display: grid;
      grid-auto-flow: column;
      grid-auto-columns: 1fr;
      gap: 2em;
      @media @mobile {
        display: flex;
        align-items: center;
        justify-items: center;
        justify-content: space-around;
        flex-direction: column;

      }
      .classResultsNumber {
        font-size: 3em;
        //line-height: 70px;
        text-align: center;
        font-family: @baskervilleRegular;

        @media @mobile {
          font-size: 3.5em;
          //font-size: 50px;
          font-weight: 300;
          //line-height: 50px;
        }
      }
      .classResultsText {
        padding-top: 1em;
        font-size: 2em;
        //line-height: 50px;
        text-align: center;
        @media @mobile {
          font-size: 1.5em;
          //font-size: 20px;
          font-weight: 100;
          //line-height: 20px;
        }
      }
    }
  }

  .successTestimonials {
    display: flex;
    flex-direction: column;
    padding-top: 4em;
    padding-bottom: 8em;
    gap: 4em;
    color: @newLandingBlue;
    align-items: center;

    .testimonialsTitle {
      //display: block;
      font-family: @baskervilleRegular;
      font-size: 4em;
      font-weight: 400;
      //line-height: 70px;
      text-align: center;

      .testimonialsTitleItalic {
        //font-style: italic;
        font-family: @baskerville;
      }

      @media @mobile {
        //font-size: 30px;
        font-weight: 300;
        //line-height: 40px;
      }

    }

    .testimonialsSubtitle {
      font-family: @gothamBook;
      font-size: 2em;
      font-weight: 325;
      //line-height: 40px;
      letter-spacing: 0px;
      text-align: center;

      @media @mobile {
        //font-size: 15px;
        font-weight: 125;
        //line-height: 25px;
        letter-spacing: -1px;
      }
    }

    .testimonialsContainer {
      padding: 3em;
      width: 100%;
      display: grid;
      grid-auto-flow: column;
      grid-auto-columns: 1fr;
      align-items: baseline;
      gap: 3em;
      @media @mobile {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 1em;
      }

      .testimonialsInd {
        background-color: @nueblue;
        padding: 2em;
        width: 100%;
        display: flex;
        flex-direction: column;
        //justify-content: space-around;
        align-content: left;
        font-family: @gothamBook;
        text-align: center;
        height: 100%;

        img {
          width: 100px;
          height: auto;
        }
        .testimonialsText {
          font-size: 1.2em;
          font-weight: 325;
          padding-left: 25px;
          padding-bottom: 2em;
          //line-height: 37px;
          letter-spacing: 0px;
          text-align: left;
          color: @eggshell;
          @media @mobile {
            //font-size: 2em;
            font-weight: 175;
            //line-height: 18px;

          }

        }
        .testimonialsName {
          font-size: 1.5em;
          font-weight: 400;
          //line-height: 25px;
          letter-spacing: 0.3em;
          @media @mobile {
            //font-size: 13px;
            font-weight: 250;
            //line-height: 15px;
            letter-spacing: 0.3em;
          }
        }
      }

    }
  }

  .carouselStepper {
    padding: 5em 5em;
    text-align: center;
    @media @mobile {
      padding: 1em 1em;
    }

    .carouselTitle{
      padding: 1em;
      text-align: center;
      h3 {
        font-family: @baskervilleRegular;
        font-size: 4em;
        font-weight: 400;
          @media @mobile {
            font-size: 2em;
            font-weight: 200;
          }
      }
      h4 {
        padding-top: 1em;
        font-family: @baskervilleRegular;
        font-size: 2em;
        font-weight: 300;
          @media @mobile {
            font-size: 1.5em;
            font-weight: 150;
          }
      }
    }


    .carousel-items {
      padding-top: 2em;
      padding-bottom: 50px;
      padding-left: 20px;
      padding-right: 20px;
      display: flex;
      gap: 10px;
      @media @mobile {
        display: block;
      }
      @media @desktop {
        gap: 10px;
      }
    }

    .carousel__item {

      p {
        text-align: left;
        color: @nueblue;
        font-size: 1.4em;
        line-height: 1.4em;
      }

      width: 92%;
      @media @desktop {
        width: 380px;
      }
      @media @mobile {
        width: 320px;
      }
    }


  .steps {
    margin-top: 4em;
    //position: relative;
    z-index: 2;
    color: @eggshell;


    .title {
      font-size: 2em;
      font-family: @montserrat;
      .italic {
        font-family: @baskerville;
      }
    }

    .offset {
      @media @desktop {
        margin-top: 7em;
      }
    }
    .step-title{
      font-size: 2em;
      font-family: @montserrat;
      font-weight: bold;
      @media @mobile {
        font-size: 1.4em;
      }
    }
    .inner-step{
      padding-top: 2em;
      font-family: @montserrat;
      font-size: 1.5em;
      @media @mobile {
        font-size: 1.2em;
      }
    }
    .no-title {
      padding-top: 0;
      text-align: center;
    }

    .container {

      color: @eggshell;
      display: grid;
      grid-auto-flow: column;
      grid-auto-columns: 1fr;

      gap: 2em;

      @media @mobile {
        display: flex;
        flex-direction: column;
        gap: 2em;
      }

      .step-landing {
        padding: 2em;
        background-color: @nueblue;
        text-align: left;
        font-family: @montserrat;
        border-radius: 2em;
        /*
        @media @desktop {
          min-height: 200px !important;
          min-width: 350px;
        }
        @media @mobile {
          min-height: 300px !important;
          width: 320px;
        }*/
      }

      > .subtitle {
        margin-top: 10vh;
        color: @nueblue;
        text-align: center;
      }

      > .title {
        font-size: 1em;
        padding-top: 0.5em;
        text-align: center;
        color: @nueblue;
        @media @desktop {
          text-align: left;
        }
      }
    }
  }
}

  .link_wrapper {
    text-align: center;
    margin-top: 1em;


  .link {
    line-height: 1em;
    font-size: 0.5em;
    padding-bottom: 6px;
    letter-spacing: -0.4px;
    border-bottom: 1.5px solid @nueblue;
    font-family: @gotham;
    display: inline-block;
    clear: both;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;

    .icon {
      height: 12px;
      width: 12px;
    }
  }
}
}
